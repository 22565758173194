<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit Product Group</strong> </CCardHeader>
          <CForm autocomplete="off">
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="First Name"
                    v-model="product_group_to_edit.name"
                    placeholder="Enter first name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="product_group_to_edit.name ? true : null"
                  />
                </CCol>     
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="Description"
                    v-model="product_group_to_edit.description"
                    placeholder="Enter Description..."                  
                  />
                </CCol>            
              </CRow>           
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductGroupEdit",

  data() {
    return {  
      product_group_to_edit: [],
      name_invalid: false,     
    };
  },
  computed: {
    ...mapState({
      editProductGroup: (state) => state.product_groups.editProductGroup,
      isSaving: (state) => state.product_groups.isSaving,
    }),
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editProductGroup) {
      this.$router.push({ name: "Product Groups" });
    }
    this.beforeEditingCache = JSON.stringify(this.editProductGroup);
    this.product_group_to_edit = this.editProductGroup
      ? JSON.parse(this.beforeEditingCache)[0]
      : [];
  },

  methods: {
    check_name() {
      if (!this.product_group_to_edit.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    },    
    checkForm() {
      if (!this.product_group_to_edit.name ) {
        this.check_name();        
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("product_groups/updateProductGroup", this.product_group_to_edit)
          .then((response) => {
            this.$router.push({ name: "Product Groups" });
          });
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 